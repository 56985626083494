@import './variables';
@import './mixins';
@import './fonts';

* {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
}

html,
body {
  width: 100%;
  height: 100%;
  margin: 0;
  overflow: hidden;
  font-family: 'DIN2014', sans-serif;
  font-weight: bold;
  font-style: italic;
  font-size: 14px;
}

main {
  width: 100%;
  height: 100%;
  position: relative;
  padding: 0;
  margin: 0;
  overflow: hidden;

  &.inverted {
    background: black;

    .logo {
      fill: white;
    }
  }
}

canvas {
  width: 100%;
  height: 100%;

  &.pics {
    position: absolute;
    z-index: 10;
    top: 0;
    left: 0;
    pointer-events: all;
  }

  &.doodles {
    position: absolute;
    z-index: 100;
    top: 0;
    left: 0;
    pointer-events: none;
  }

  &.arts {
    position: relative;
  }

  &.hidden {
    z-index: -100;
    visibility: hidden;
  }
}

button {
  position: absolute;
  left: 30px;
  top: 20vh;
  max-width: 160px;
  border: none;
  background: rgba(255, 255, 255, 0);
  border-radius: 50%;
  outline: none;
  cursor: pointer;

  @media screen and (orientation: portrait) {
    width: 25vw;
  }
  @media screen and (orientation: landscape) {
    top: 22vh;
    width: 25vh;
  }

  &.why {
    z-index: 1001;
  }

  &.invert {
    z-index: 1000;

    &:before {
      content: '';
      display: block;
      max-height: 120px;

      @media screen and (orientation: portrait) {
        height: 16vw;
      }
      @media screen and (orientation: landscape) {
        height: 16vh;
      }
    }
  }

  &.close {
    top: 20px;
    right: -20px;
    left: unset;
    z-index: 1011;
  }

  img {
    width: 100%;
  }
}

.logo {
  position: absolute;
  z-index: 9999;
  top: 12px;
  left: 12px;
  width: 160px;
  height: 14%;
  stroke: none;
  fill: black;
}

.info {
  position: absolute;
  z-index: 1010;
  top: 17vh;
  left: 0;
  min-width: 320px;
  min-height: 320px;
  max-width: 500px;
  max-height: 500px;
  background: #fff100;

  border-radius: 50%;
  transition: all 200ms ease-in-out;
  transform: translateX(-110%);

  &.visible {
    transform: translateX(-25%);
  }

  .text {
    width: 45%;
    margin-top: 22%;
    margin-left: calc(25% + 26px);
    font-size: 1.6em;

    @media (min-width: $sm) and (min-height: $sm) {
      font-size: 2.4em;
    }

    @media (min-width: $md) {
      font-size: 2.4em;
    }
  }

  @media screen and (orientation: portrait) {
    height: 80vw;
    width: 80vw;
  }
  @media screen and (orientation: landscape) {
    height: 80vh;
    width: 80vh;
  }
}
